<template>
  <div>
    <v-card v-if="isStudentInfoVisible" class="mb-4" v-bind="$attrs">
      <v-system-bar>Person</v-system-bar>
      <v-list>
        <ListItem title="amtlicher Vorname">
          <StaticText :value="profile.officialFirstName" />
        </ListItem>
      </v-list>
      <v-card-text v-if="isCommentsVisible"
        >Diese Informationen sind für die Mitarbeitenden der Schule
        sichtbar.</v-card-text
      >
    </v-card>

    <v-card v-if="isEmployeeInfoVisible" class="mb-4" v-bind="$attrs">
      <v-system-bar>Person</v-system-bar>
      <v-list>
        <ListItem title="IBAN">
          <StaticText :value="profile.iban" />
        </ListItem>
      </v-list>
      <v-card-text v-if="isCommentsVisible"
        >Diese Informationen sind für die Schuladministration
        sichtbar.</v-card-text
      >
    </v-card>

    <v-card v-if="isPermissionsVisible" class="mb-4" v-bind="$attrs">
      <v-system-bar>Spezialberechtigungen</v-system-bar>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-chip-group column>
              <v-chip
                class="pt-0 mt-0"
                outlined
                v-for="(permission, index) in profile.permissions"
                :key="index"
                :value="permission"
              >
                {{ permission.description }}
              </v-chip>
            </v-chip-group>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>

    <v-card v-if="isEmployeeInfoVisible" class="mb-4" v-bind="$attrs">
      <v-system-bar>Anstellung</v-system-bar>
      <v-list>
        <ListItem title="Personalkategorie">
          <LookupValue :value="profile.employeeCategory" />
        </ListItem>
        <ListItem title="Personalnummer">
          <StaticText :value="profile.employeeNumber" />
        </ListItem>
        <ListItem title="Eintrittsdatum">
          <DateValue :value="profile.entryDate" />
        </ListItem>
        <ListItem title="Austrittsdatum">
          <DateValue :value="profile.exitDate" />
        </ListItem>
      </v-list>
      <v-card-text v-if="isCommentsVisible"
        >Diese Informationen sind für Schuladministration sichtbar.</v-card-text
      >
    </v-card>

    <v-card v-if="isStudentInfoVisible" class="mb-4" v-bind="$attrs">
      <v-system-bar>Bildungsgang</v-system-bar>
      <v-list>
        <ListItem title="Klassenanmeldung">
          <LookupValue :value="profile.status" />
        </ListItem>
        <ListItem title="besondere rechtliche Lage">
          <LookupValue :value="profile.specialLegalStatus" />
        </ListItem>
        <ListItem title="Eintrittsdatum">
          <DateValue :value="profile.entryDate" />
        </ListItem>
        <ListItem title="Austrittsdatum">
          <DateValue :value="profile.exitDate" />
        </ListItem>
      </v-list>
      <v-card-text v-if="isCommentsVisible"
        >Diese Informationen sind für die Mitarbeitenden der Schule
        sichtbar.</v-card-text
      >
    </v-card>

    <v-card v-if="isStudentInfoVisible" class="mb-4" v-bind="$attrs">
      <v-system-bar>Fächerprofil</v-system-bar>
      <v-list>
        <ListItem title="zweisprachiger Unterricht (BI)">
          <LookupValue long :value="profile.bilingual" />
        </ListItem>
        <ListItem title="Kunstfach (KU)">
          <LookupValue long :value="profile.artsSubject" />
        </ListItem>
        <ListItem title="dritte Sprache (S3)">
          <LookupValue long :value="profile.thirdLanguage" />
        </ListItem>
        <ListItem title="Schwerpunktfach (SF)">
          <LookupValue long :value="profile.majorSubject" />
        </ListItem>
        <ListItem title="Schwerpunktfach plus (SF+)">
          <LookupValue long :value="profile.majorSubjectPlus" />
        </ListItem>
        <ListItem title="Ergänzungsfach (EF)">
          <LookupValue long :value="profile.complementarySubject" />
        </ListItem>
        <ListItem title="Sprache plus (S+)">
          <LookupValue long :value="profile.languagePlus" />
        </ListItem>
        <ListItem title="zweite Sprache (S2)">
          <LookupValue long :value="profile.secondLanguage" />
        </ListItem>
        <ListItem title="Sport (SP)">
          <LookupValue long :value="profile.physicalEducation" />
        </ListItem>
        <ListItem title="Nachhaltige Entwicklung extended (NExt)">
          <StaticText :value="profile.next" />
        </ListItem>
        <ListItem title="fünftes Prüfungsfach (P5)">
          <LookupValue long :value="profile.fifthSubject" />
        </ListItem>
      </v-list>
      <v-card-text v-if="isCommentsVisible"
        >Diese Informationen sind für die Mitarbeitenden der Schule und die
        eigene Klasse sichtbar.</v-card-text
      >
    </v-card>
    <v-card v-if="isStatisticsInfoVisible">
      <v-system-bar>Statistik</v-system-bar>
      <v-list>
        <ListItem title="AHV-Nummer">
          <StaticText :value="profile.socialSecurityNumber" />
        </ListItem>
        <ListItem title="Heimatort">
          <StaticText :value="profile.placeOfOrigin" />
        </ListItem>
        <ListItem title="Wohngemeinde">
          <LookupValue :value="profile.municipality" />
        </ListItem>
        <ListItem title="Staatsangehörigkeit">
          <LookupValue :value="profile.citizenship" />
        </ListItem>
        <ListItem title="Erstsprache">
          <LookupValue :value="profile.nativeLanguage" />
        </ListItem>
      </v-list>
      <v-card-text v-if="isCommentsVisible"
        >Diese Informationen werden anonymisiert für die Schulstatistik des
        Bundesamts für Statistik benötigt und sind nur für Kanzlei und
        Schulleitung sichtbar.</v-card-text
      >
    </v-card>
  </div>
</template>

<script>
import DateValue from "common/components/DateValue.vue";
import ListItem from "@/components/ListItem.vue";
import LookupValue from "common/components/LookupValue.vue";
import StaticText from "common/components/StaticText.vue";

import { age } from "common/utils/date.js";

export default {
  name: "SchoolProfile",
  components: { DateValue, ListItem, LookupValue, StaticText },
  props: {
    person: { type: Object, default: null },
  },
  data() {
    return {
      loading: false,
      profile: {},
    };
  },
  watch: {
    person() {
      this.fetchData();
    },
  },
  computed: {
    hasPerson() {
      return this.person && this.person.id;
    },
    isCommentsVisible() {
      return this.$_isPerson(this.person);
    },
    isEmployeeInfoVisible() {
      return (
        this.hasPerson &&
        (this.person.type.code === "employee" ||
          this.person.type.code === "teacher")
      );
    },
    isPermissionsVisible() {
      return this.profile.permissions;
    },
    isStatisticsInfoVisible() {
      return (
        this.showStudentInfo &&
        (this.$_hasRole("schoolAdmin") || this.$_isPerson(this.person))
      );
    },
    isStudentInfoVisible() {
      return this.hasPerson && this.person.type.code === "student";
    },
  },
  methods: {
    age,
    async fetchData() {
      this.profile = {};
      if (!this.hasPerson) return;
      this.loading = true;
      this.profile = await this.apiGet({
        resource: "person/profile",
        id: this.person.id,
      });

      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
